import {
  Box,
  Heading,
  List,
  ListItem,
  UnorderedList,
  Link as ChakraLink,
} from "@chakra-ui/react"
import {
  EntityId,
  NavigationEntityHydrated,
  NavigationLinkEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import { Link as GatsbyLink } from "gatsby"
import { trim } from "lodash"
import React from "react"
import { NavigationTreeItem, PageTreeItem } from "~/services/Utils"
import HtmlAddon from "../navigation/HtmlAddon"
interface Props {
  pageContext: {
    tree: PageTreeItem[] | NavigationTreeItem[]
    navigation: NavigationEntityHydrated
    baseUrl: string
    pages: PageEntityHydrated[]
    sitemapNavigationId: EntityId
  }
}

const SiteMap: React.FC<Props> = ({
  pageContext: { tree, baseUrl, sitemapNavigationId, pages },
}) => {
  return (
    <>
      <Heading as="h1" mb={6}>
        Sitemap
      </Heading>
      <List className="navigation-sitemap">
        {tree.map(item => (
          <SiteMapItem
            item={item}
            key={item.id}
            baseUrl={baseUrl}
            isNavigation={sitemapNavigationId !== null}
            level={0}
            pages={pages}
          />
        ))}
      </List>
    </>
  )
}

export default SiteMap

const listStyleTypes = ["disc", "circle", "square"]

const NavigationItem: React.FC<{
  link: NavigationLinkEntityHydrated
  baseUrl: string
  pages: PageEntityHydrated[]
}> = ({ link, baseUrl, pages }) => {
  const { type, path, label, external, labelPrefixHtml, labelSuffixHtml } = link

  switch (type) {
    case "link":
      if (external) {
        return (
          <>
            <HtmlAddon placement={"left"} html={labelPrefixHtml} />
            <ChakraLink href={path} rel="noopener" isExternal>
              {label}
            </ChakraLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml} />
          </>
        )
      } else {
        return (
          <>
            <HtmlAddon placement={"left"} html={labelPrefixHtml} />
            <GatsbyLink to={path}>{label}</GatsbyLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml} />
          </>
        )
      }
    case "page":
      const page = pages.find(({ id }) => id === link.pageId)
      return (
        <>
          <HtmlAddon placement={"left"} html={labelPrefixHtml} />
          <GatsbyLink to={`${baseUrl}${trim(page?.path!, "/")}`}>
            {label}
          </GatsbyLink>
          <HtmlAddon placement={"right"} html={labelSuffixHtml} />
        </>
      )
    case "text":
      return (
        <>
          <HtmlAddon placement={"left"} html={labelPrefixHtml} />
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml} />
        </>
      )
    default:
      return <></>
  }
}

const SiteMapItem: React.FC<{
  item: PageTreeItem | NavigationTreeItem
  baseUrl: string
  isNavigation: boolean
  level: number
  pages: PageEntityHydrated[]
}> = ({ item, baseUrl, isNavigation, level, pages }) => {
  const isParent = (item.children?.length || 0) > 0
  const Link = (
    <Box
      _hover={{ textDecoration: "underline" }}
      display="inline-block"
      color="blue.500"
    >
      {isNavigation ? (
        <NavigationItem
          link={item as NavigationLinkEntityHydrated}
          baseUrl={baseUrl}
          pages={pages}
        />
      ) : (
        <GatsbyLink to={`${baseUrl}${(item as PageEntityHydrated).path!}`}>
          {(item as PageEntityHydrated).name}
        </GatsbyLink>
      )}
    </Box>
  )

  const listStyleType =
    level < listStyleTypes.length ? listStyleTypes[level] : "square"

  if (isParent) {
    return (
      <>
        <ListItem className={`level-${level}`} listStyleType={listStyleType}>
          {Link}
          <UnorderedList>
            {item.children!.map(subItem => (
              <SiteMapItem
                item={subItem}
                key={subItem.id}
                baseUrl={baseUrl}
                isNavigation={isNavigation}
                level={level + 1}
                pages={pages}
              />
            ))}
          </UnorderedList>
        </ListItem>
      </>
    )
  }

  return (
    <ListItem
      key={item.id}
      className={`level-${level}`}
      listStyleType={listStyleType}
    >
      {Link}
    </ListItem>
  )
}
